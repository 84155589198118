import React from 'react';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Layout from '../components/Layout'; // eslint-disable-line
import SEO from '../components/seo'; // eslint-disable-line
import Header from '../components/Header';
import Footer from '../components/Footer';

import { store, persistor } from '../store';

import { View, Container } from '../components/pages/styles';

export default function Termo() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
            <SEO title="Termo de uso" />
            <Header />
            <View>
              <Container>
                <div className="bloco">
                <h3>Política de Privacidade</h3>
                <p>Para reafirmar nosso compromisso com a segurança, sigilo e privacidade das informações pessoais de nossos visitantes, usuários e clientes, elaboramos a presente Política de Privacidade.</p>
<p>Nesta política abordaremos o tratamento que o ADOTAPET dá às informações que podem identificar seus visitantes, usuários e clientes, sejam elas coletadas diretamente no seu portal ou em outros meios, como, por exemplo, cadastros no meio físico e armazenadas em seu banco de dados eletrônico.</p>
<p>Leia com atenção, pois ao utilizar o portal ADOTAPET o visitante estará concordando com os termos e condições previstos nesta Política de Privacidade e com todos os avisos a ela pertinentes que possam aparecer no portal ADOTAPET.</p>
<p>1. Definições</p>
<p>Denomina-se &quot;ADOTAPET&quot; o portal, como um todo;</p>
<p>Classificam-se como &quot;visitantes&quot; todos aqueles que de alguma forma acessam o portal ADOTAPET.</p>
<p>Classificam-se como &quot;usuários&quot; todos aqueles que se cadastram no portal ADOTAPET e recebem uma conta de acesso individual e exclusiva.</p>
<p>Classificam-se como &quot;clientes&quot; todos aqueles que adquirem os serviços oferecidos pelo portal ADOTAPET, pagos ou não.</p>
<p>Entende-se por “identificação individual” toda informação que não está disponível para o público em geral e é atribuída, de forma personalizada, a um determinado cliente ou usuário. São exemplos de dados de identificação individual os números de CPF ou CNPJ, e-mail, dados para contato e dados sobre cobrança.</p>
<p>Cookie é um arquivo-texto que pode ser enviado pelo servidor do portal ADOTAPET para o computador do visitante e ali armazenado com a finalidade de identificar o computador, personalizar os acessos e obter dados de navegação, como páginas visitadas ou links clicados. Um cookie é atribuído individualmente a cada computador, ele não pode ser usado para executar programas, tampouco infectar computadores com códigos maliciosos de qualquer espécie, tais como vírus, trojans, etc., e pode ser lido apenas pelo servidor que o enviou.</p>
<p>Criptografia é o nome dado ao processo de codificação de informações. As informações são codificadas (embaralhadas) na origem e decodificadas no destino, dificultando, dessa forma, que sejam decifradas durante o tráfego na internet.</p>
<p>Firewall: é o nome dado ao dispositivo físico e/ou lógico de uma rede de computadores que tem por objetivo aplicar uma política de segurança a um determinado ponto de controle da rede. Sua função consiste em regular o tráfego de dados entre redes distintas e impedir a transmissão e/ou recepção de acessos nocivos ou não autorizados de uma rede para outra.</p>
<p>2. Objetivo da solicitação de informações pessoais</p>
<p>2.1. Uma das principais diretrizes do ADOTAPET é o respeito pelo indivíduo. Assim, temos o compromisso de manter suas informações pessoais sob o mais completo sigilo, vinculando-as unicamente ao seu registro e utilizando-as para:</p>
<p>Realizar a cobrança dos serviços adquiridos no portal, bem como a identificação do respectivo destinatário;</p>
<p>Obter estatísticas genéricas para identificação do perfil de nossos clientes e desenvolvimento de nossas campanhas;</p>
<p>Manutenção de dados e ações de relacionamento com o cliente, bem como a melhoria contínua do serviço prestado pelo ADOTAPET.</p>
<p>Resolução de eventuais questões legais relacionadas ao portal.</p>
<p>2.2. As informações solicitadas aos usuários são as mínimas necessárias para que o ADOTAPET possa disponibilizar ao consumidor uma forma para efetuar suas compras.</p>
<p>2.3. O usuário garante a veracidade e exatidão das informações e dados que fornecer ao ADOTAPET, assumindo a correspondente responsabilidade caso as mesmas não sejam exatas, bem como se compromete a mantê-las atualizadas. O ADOTAPET não assume qualquer responsabilidade em caso de danos causados pela inexatidão dos dados informados.</p>
<p>2.4 O usuário que se cadastrar no portal ADOTAPET utilizando dados de terceiros poderá incorrer nos crimes de falsa identidade, estelionato ou outro mais grave, sem prejuízo de eventual responsabilização por legislação específica.</p>
<p>2.5. O usuário poderá, a qualquer momento, solicitar alteração e/ou retificação de seus dados, bastando para tanto, acessar seu cadastro diretamente em seu perfil no portal ADOTAPET.</p>
<p>2.6. O portal ADOTAPET, diante da natureza de seu negócio, exige de seus usuários idade mínima de 18 anos.</p>
<p>3. Obtenção das informações pessoais</p>
<p>3.1 São coletadas informações pessoais capazes de identificar os usuários quando estes:</p>
<p>a) se cadastram portal ADOTAPET;</p>
<p>b) efetuam o pagamento de seu pedido através do portal ADOTAPET;</p>
<p>3.2 Todos os dados pessoais coletados serão incorporados à base de dados do ADOTAPET, cabendo ao ADOTAPET o armazenamento destes dados.</p>
<p>3.3 O ADOTAPET poderá confirmar os dados pessoais informados pelo usuário consultando entidades públicas, companhias especializadas, banco de dados lícitos, para o que fica expressamente autorizado. Tal atitude, contudo, não cessa nem diminui a responsabilidade do usuário quanto à exatidão e veracidade dos dados que fornecer motivo pelo qual o ADOTAPET poderá, a seu exclusivo critério, suspender e/ou cancelar o cadastro do usuário, a qualquer momento, caso detecte qualquer inexatidão.</p>
<p>3.4 O ADOTAPET poderá permanecer com o registro de dados e informações dos visitantes pelo período máximo de 5 (cinco) anos para fins de auditoria e preservação de direitos, com a faculdade de excluí-los definitivamente segundo sua conveniência em período menor.</p>
<p>4. Obtenção das informações navegacionais</p>
<p>4.1. O ADOTAPET também recebe e armazena automaticamente informações em seus servidores sobre as atividades advindas portal, incluindo o momento quando ocorre uma doação/adoção.</p>
<p>5. Utilização das informações</p>
<p>5.1. Sem prejuízo das demais disposições descritas nesta política, o usuário declara ter ciência e concorda que o ADOTAPET poderá utilizar as informações coletadas para os seguintes propósitos gerais:</p>
<p>(a) viabilizar a interatividade entre usuário cliente e usuário administrador;</p>
<p>(b) informar a respeito de novos eventos;</p>
<p>(c) manter atualizados os cadastros dos usuários para fins de contato telefônico, por correio eletrônico, via mala direta, SMS ou outros meios de comunicação;</p>
<p>(d) aperfeiçoar a usabilidade e a experiência interativa durante a utilização do usuário no portal;</p>
<p>(e) elaborar estatísticas gerais, sem que haja identificação dos usuários;</p>
<p>(f) garantir a segurança dos visitantes e responder a suas eventuais dúvidas ou solicitações;</p>
<p>(g) constituir, defender ou exercer regularmente direitos em âmbito judicial ou administrativo;</p>
<p>(h) realizar pesquisas e campanhas de comunicação e marketing de relacionamento, bem como divulgar ofertas de parceiros.</p>
<p>6. Da proteção e privacidade dos dados</p>
<p>6.1. O ADOTAPET fará todo o possível para manter a privacidade dos dados pessoais de seus usuários armazenados em sua base, comprometendo-se a utilizar tecnologia que seja suficientemente adequada para a proteção de tais dados, procurando manter o ambiente seguro, com uso de ferramentas apropriadas e controles eficientes de segurança da informação sempre observando o Estado da técnica disponível. Contudo, considerando que nenhum sistema de segurança é absolutamente seguro, o ADOTAPET se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de vírus, invasões no portal ADOTAPET, em sua base de dados e demais falhas relacionadas, salvo se houver dolo ou culpa do ADOTAPET.</p>
<p>6.2. Os profissionais do ADOTAPET têm conhecimento da presente política e apenas o pessoal qualificado e autorizado tem permissão para acessar os dados pessoais dos usuários coletados no portal, sob pena de sofrerem sanção disciplinar em caso de violação das normas aqui previstas.</p>
<p>6.3. O ADOTAPET exige de suas empresas parceiras a garantia de proteção e privacidade de dados assegurados nesta política, quando há necessidade de compartilhamento dos mesmos.</p>
<p>7. Procedimentos de segurança</p>
<p>7.1. O ADOTAPET se reveste das medidas mais modernas de segurança para proteger suas informações pessoais, restringindo o acesso a elas apenas a pessoas devidamente autorizadas.</p>
<p>7.2 Para tanto o portal ADOTAPET utiliza um moderno sistema de segurança de codificação para transações na internet, o que o torna um ponto seguro na rede mundial de computadores.</p>
<p>8. Envio de comunicados e mensagens publicitárias</p>
<p>8.1 O ADOTAPET poderá enviar comunicados e mensagens publicitárias diariamente, a seu exclusivo critério, a seus usuários cadastrados no portal ADOTAPET, ou clientes em geral, fazendo uso de todos os tipos de tecnologias e meios de comunicação disponíveis, seja por e-mail, SMS, MMS, mala-direta, outros.</p>
<p>8.2 Alguns provedores oferecem uma ferramenta Anti-Spam para seus usuários, a fim de bloquear e-mails não desejados. Se você utiliza algum tipo de Anti-Spam, você poderá não receber os e-mails doADOTAPET, até mesmo os e-mails de confirmação de seus pedidos. Adicione os e-mails contato@ADOTAPET.com e ADOTAPET@ADOTAPET.com à sua lista de remetentes seguros para receber todas as comunicações sem problemas.</p>
<p>8.3 Os serviços de envio de e-mails podem ser realizados pelo ADOTAPET ou por empresa contratada, que utilizará seus próprios servidores para realizar o envio.</p>
<p>8.4 A empresa contratada não armazena nem utiliza de nenhuma forma os e-mails do cadastro do portal ADOTAPET para qualquer outro fim que não o envio das mensagens, de acordo com as preferências de cada usuário registradas e as disposições previstas na presente Política de Privacidade e nos Termo de Uso do portal.</p>
<p>9. Modificações de nossa política de privacidade</p>
<p>9.1. O ADOTAPET reserva-se ao direito de alterar a Política de Privacidade a qualquer momento visando sua constante melhoria e aprimoramento e toda alteração será devidamente informada.</p>
<p>9.2. Ao utilizar funcionalidades do portal ADOTAPET ou interagir com ele, o usuário concorda e aceita os termos da Política de privacidade vigente na data, portanto, deve verificá-la previamente cada vez que visitar este site.</p>
<p>10. Disposições gerais</p>
<p>10.1 A presente Política de Privacidade é parte integrante dos Termos e Condições de Uso do portal ADOTAPET.</p>
<p>11. Lei aplicável e jurisdição</p>
<p>11.1 A presente Política de Privacidade é regida pela legislação da República Federativa do Brasil. Seu texto deverá ser interpretado no idioma português e os visitantes e usuários do portal ADOTAPET submetem-se ao Foro da Comarca de Fortaleza / Ceará.</p>

                </div>
              </Container>
            </View>

            <Footer />

            <ToastContainer autoClose={3000} />
        </Layout>
      </PersistGate>
    </Provider>
  );
}
